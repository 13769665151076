import React, { useState } from "react";

// import styles
import "./MsgForm.scoped.css";
import "../../css/global.css";

const MsgForm = () => {
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState([]);

  const checkForm = (e) => {
    if (name && mail && msg) {
      alert("Message was sent.");
    }
    setErrors([]);
    if (!name) {
      setErrors((errors) => [...errors, "Name wird benötigt"]);
    }
    if (!mail) {
      setErrors((errors) => [...errors, "Mail wird benötigt"]);
    }
    if (!msg) {
      setErrors((errors) => [...errors, "Nachricht wird benötigt"]);
    }
    e.preventDefault();
  };

  return (
    <form className="form">
      <p className="bold">
        Ganz einfach und bequem das Formular ausfüllen und wir melden uns
        alsbald zurück:
      </p>
      <input
        placeholder="Name*"
        className="rounded shadowConcave"
        type="text"
        onChange={setName}
      />
      <input
        placeholder="E-Mail*"
        className="rounded shadowConcave"
        type="email"
        onChange={setMail}
      />
      <textarea
        placeholder="Nachricht*"
        className="textArea rounded shadowConcave"
        onChange={setMsg}
      />
      <div>
        {errors.map((x) => (
          <p style={{ color: "var(--harmony)", margin: "1rem 0" }} key={x}>
            * {x}
          </p>
        ))}
      </div>

      <input
        type="submit"
        value="Submit"
        className="btn rounded shadowFlat"
        onClick={(e) => checkForm(e)}
      />
    </form>
  );
};

export default MsgForm;
