// import libraries, variables
import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../helper/functions";

// import components
// import Section from "../components/Section/Section";
import NavLink from "../../components/NavLink/NavLink";
import DropDown from "../../components/DropDown/DropDown";

const About = () => {
  const [aboutUs, setAboutUs] = useState(null);
  const [openIndex, setOpenIndex] = useState();

  const getAboutUs = async () => {
    const res = await axios.get(`${url}/about-us`);
    setAboutUs(res.data);
  };

  const handleToggle = (key) => {
    setOpenIndex(openIndex !== key ? key : null);
  };

  useEffect(() => {
    getAboutUs();
  }, []);

  if (!aboutUs) return null;

  return (
    <div className="top">
      <h2 style={{ marginBottom: "2rem" }}>{aboutUs.title}</h2>
      {aboutUs.services.map((service) => (
        <DropDown
          key={service.id}
          id={service.id}
          title={service.title}
          text={service.description}
          isOpen={openIndex === service.id}
          toggle={handleToggle}
        />
      ))}
      <NavLink link="contact" title="Contact Us" />
    </div>
  );
};

export default About;
