// import libraries, variables
import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../helper/functions";

// import components
import Section from "../../components/Section/Section";
import MsgForm from "../../components/MsgForm/MsgForm";
import NavLink from "../../components/NavLink/NavLink";

// import styles
import "./Contact.css";

const Contact = () => {
  const [companyInfo, setCompanyInfo] = useState(null);

  const getCompanyInfo = async () => {
    const res = await axios.get(`${url}/company-information`);
    setCompanyInfo(res.data);
  };

  useEffect(() => getCompanyInfo(), []);

  if (!companyInfo) return null;

  // render elements

  return (
    <div>
      <h2 className="top">Contact</h2>
      <Section
        title="Toller Title"
        description="Lass uns zusammen arbeiten und etwas Tolles kreieren."
      />
      <div className="contact">
        <h4>Name</h4>
        <h3>{companyInfo.title}</h3>
        <h4>E-Mail</h4>
        <h3>{companyInfo.email}</h3>
        <h4>Adresse</h4>
        <h3>
          {companyInfo.street}
          <br />
          {companyInfo.postalCode} {companyInfo.city}
        </h3>
        <h4>Telefon</h4>
        <h3>+49 {companyInfo.phone}</h3>
      </div>
      <Section
        bg="white"
        title="Get in touch"
        description="Find out if we are available and ask us anything."
      />
      <MsgForm />
      <NavLink title="Portfolio" link="portfolio" />
    </div>
  );
};

export default Contact;
