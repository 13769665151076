import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import views
import Home from "./views/Home/Home";
import Portfolio from "./views/Portfolio/Portfolio";
import About from "./views/About/About";
import Contact from "./views/Contact/Contact";
import ProjectDetails from "./views/Portfolio/ProjectDetails";
import Imprint from "./views/Extra/Imprint";
import DataSecurityStatement from "./views/Extra/DataSecurityStatement";

// import lib
import Header from "./lib/Header";
import Footer from "./lib/Footer";

// import components
import Menu from "./components/Menu/Menu";

// import styles
import "./css/global.css";

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/portfolio/:id"
          // render={(props) => <ProjectDetails {...props} />}
          element={<ProjectDetails />}
        />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/data-security" element={<DataSecurityStatement />} />
      </Routes>
      <Menu />
      <Footer />
    </BrowserRouter>
  );
};

export default App;
