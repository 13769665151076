// import libraries and variables
import React from "react";
import ReactMarkdown from "react-markdown/";

// import styles
import "./DropDown.scoped.css";

import PropTypes from "prop-types";

const DropDown = ({ id, title, text, toggle, isOpen }) => {
  return (
    <div className="shadowFlat dropDown">
      <button className="btnLong" onClick={() => toggle(id)}>
        {title}
      </button>

      {isOpen && <ReactMarkdown className="text">{text}</ReactMarkdown>}
    </div>
  );
};

// proptypes
DropDown.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

DropDown.defaulProps = {
  title: "title",
  text: "Lorem ipsum dolor sit amet...",
};
export default DropDown;
