import React from "react";

// import styles
import "./IconSocialMedia.scoped.css";

const IconSocialMedia = ({ alt, iconUrl, smUrl }) => {
  return (
    <a href={smUrl} target="_blank" rel="noreferrer">
      <img className="icon-social-media" src={iconUrl} alt={`${alt} logo`} />
    </a>
  );
};

export default IconSocialMedia;
