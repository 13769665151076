import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Header.scoped.css";

import logo from "../assets/svg/logo.svg";

const Header = () => {
  const [scroll, setScrolled] = useState(false);

  const scrolled = () => {
    const distance = document.documentElement.scrollTop;
    if (distance > 150) {
      setScrolled(true);
    } else if (distance <= 150) {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", scrolled);

  return (
    <header className="header">
      <div className={"logo" + (scroll ? " logoSmall" : "")}>
        <Link to="/">
          <img className="img" src={logo} alt="blueberry media logo" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
