import React from "react";
import PropTypes from "prop-types";

// import styles
import "./Section.scoped.css";

const Section = ({ title, description, bg }) => {
  // define proptypes
  Section.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
  };

  Section.defaultProps = {
    bg: "#e8e8f3",
    title: "Titel",
    description: "Beschreibung",
  };

  return (
    <div style={{ background: bg }}>
      <div className="wrapper">
        <h2 className="heading">{title}</h2>
        <h3 className="heading">{description}</h3>
      </div>
    </div>
  );
};

export default Section;
