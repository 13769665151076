import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// import helper
import { scrollToTop } from "../../helper/functions";

// import styles
import "./NavLink.scoped.css";

const NavLink = ({ link, title }) => {
  return (
    <div className="navLink">
      <Link to={`/${link}`} onClick={scrollToTop}>
        <h1>{title}</h1>
      </Link>
    </div>
  );
};

// define prototypes
Link.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
};

export default NavLink;
