// import packages
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import parse from "html-react-parser";
import { url } from "../../helper/functions";

// import components
import CardImage from "../../components/CardImage/CardImage";
import IconSocialMedia from "../../components/IconSocialMedia/IconSocialMedia";

// import styles
import "./PortfolioDetails.scoped.css";
import "../../css/global.css";

const ProjectDetails = () => {
  // get project details
  const [project, setProjects] = useState(null);
  const [active, setActive] = useState(false);
  const { id } = useParams();

  const getProjects = async (id) => {
    const res = await axios.get(`${url}/projects/${id}`);
    setProjects(res.data);
  };

  // show project details

  // scroll to element
  const refView = useRef(null);
  const scroll = () => refView.current.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    getProjects(id);
  }, [id]);

  // project init
  if (!project) return null;

  const iconRenderColor = project.socialMediaInfo.map((sm) => (
    <IconSocialMedia
      smUrl={sm.socialMediaUrl}
      key={sm.id}
      iconUrl={`${url}${sm.social_media.iconColor.url}`}
      alt={sm.social_media.label}
    />
  ));

  // render begins
  return (
    <div>
      {/* landing */}
      <div className="pd-landing">
        <CardImage img="female2.jpg" ratio={"0.85"} />
        <div className="iconRenderColor">{iconRenderColor}</div>
        <p className="harmony pd-heading">Über das Projekt</p>
        <h1 className="pd-title">{project.title}</h1>
        <button
          className="pd-arrow"
          onClick={() => [scroll, setActive(!active)]}
        >
          <h4>Show more</h4>
        </button>
      </div>
      {/* -
-
-
-
-
-
- */}
      {/* content */}
      {active && (
        <div ref={refView} className="pd-content">
          <CardImage img="female1.jpg" ratio={"0.6"} />
          <h3 className="harmony pd-large">
            "{parse(project.shortDescription)}"
          </h3>
          <p>Image Slider</p>
          <CardImage img="female2.jpg" ratio={"1"} />
          <h3>Success Story</h3>
          <p className="pd-success-story">{parse(project.successStory)}</p>
          <CardImage img="female1.jpg" ratio={"2"} />

          <div style={{ margin: "1rem 0" }}>
            <a
              href={project.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="harmony pd-heading"
            >
              Hier geht es zum Projekt
            </a>
          </div>
          <div>{iconRenderColor}</div>
          <div>
            <p style={{ marginTop: "1rem" }} className="pd-heading">
              Categories:
            </p>

            <div style={{ marginBottom: "1rem" }}>
              {project.categories
                .map((ctgy) => <span key={ctgy.id}>{ctgy.label}</span>)
                .reduce((prev, curr) => [prev, " - ", curr])}
            </div>
          </div>
          <div style={{ marginBottom: "5rem" }}>
            {project.relatedProjects.map((relPro) => (
              <h2 key={relPro.id}>{relPro.title}</h2>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
