// import packages
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../helper/functions";

// import components
import CardImage from "../../components/CardImage/CardImage";
import NavLink from "../../components/NavLink/NavLink";

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState(null);
  const [projects, setProjects] = useState(null);

  const getPortfolio = async () => {
    const res = await axios.get(`${url}/portfolio`);
    setPortfolio(res.data);
  };

  const getProjects = async () => {
    const res = await axios.get(`${url}/projects`);
    setProjects(res.data);
  };

  useEffect(() => {
    getPortfolio();
    getProjects();
  }, []);

  if (!projects) {
    return null;
  } else {
    return (
      <div className="top" style={{ margin: "0 1rem" }}>
        <h2>{portfolio.title}</h2>
        {projects.map((project) => (
          <Link to={`/portfolio/${project.id}`} key={project.id}>
            <CardImage
              ratio={"1"}
              title={project.title}
              categories={project.categories
                .map((ctgy) => <span key={ctgy.id}>{ctgy.label}</span>)
                .reduce((prev, curr) => [prev, " - ", curr])}
              img="female1.jpg"
            />
          </Link>
        ))}
        <NavLink link="about" title="About Us" />
      </div>
    );
  }
};

export default Portfolio;
