import React from "react";
import PropTypes from "prop-types";

// import components

// import styles
import "./CardImage.scoped.css";

const CardImage = ({ img, title, categories, ratio, iconUrl, label }) => {
  let imgUrl = "url('" + require(`../../assets/img/${img}`).default + "')";

  CardImage.propTypes = {
    imgUrl: PropTypes.string,
    title: PropTypes.string,
    ratio: PropTypes.string,
    // iconUrl: PropTypes.array,
  };

  return (
    <div
      style={{ backgroundImage: imgUrl, aspectRatio: ratio }}
      className="section shadowFlat card"
    >
      <div className="card-info">
        <div className="card-text">
          <h5 className="title">{title}</h5>
          <span className="harmony">{categories}</span>
        </div>
        <div className="card-social-media" />
      </div>
    </div>
  );
};

export default CardImage;
