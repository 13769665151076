// import libraries, variables
import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../helper/functions";

import image1 from "../../assets/img/female1.jpg";
import image2 from "../../assets/img/male2.jpg";

// import components

// import styles
import "./Home.css";

const Home = () => {
  const [homePage, setHomePage] = useState(null);

  const getHomePage = async () => {
    const res = await axios.get(`${url}/homepage`);
    setHomePage(res.data);
  };

  useEffect(() => {
    getHomePage();
  }, []);

  if (!homePage) return null;

  return (
    <div className="top home-screen">
      <div className="column">
        <img className="home-image" src={image1} alt="web" />
        <button className="btn shadowFlat home-rounded">Media</button>
      </div>
      <div className="column">
        <img className="home-image" src={image2} alt="media" />
        <button className="btn shadowFlat home-rounded">Web</button>
      </div>
    </div>
  );
};

export default Home;
