import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import { url } from "../../helper/functions";

import "../../css/global.css";
import "./Extra.css";

const Imprint = () => {
  const [imprint, setImprint] = useState(null);

  const getImprint = async () => {
    const res = await axios.get(`${url}/imprint`);
    setImprint(res.data);
  };

  useEffect(() => {
    getImprint();
  }, []);

  if (!imprint) return "Loading... ";

  return (
    <div className="extra-container top">
      <h2 className="extra-text">{imprint.title}</h2>
      <ReactMarkdown className="extra-text">
        {imprint.imprintText}
      </ReactMarkdown>
    </div>
  );
};

export default Imprint;
