import React from "react";
import { Link } from "react-router-dom";

// import styles
import "./Footer.scoped.css";
import "../css/animation.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="links">
        <Link className="ext-link" to="/imprint">
          Impressum
        </Link>
        <Link className="ext-link" to="/data-security">
          Datenschutz
        </Link>
        <a
          className="ext-link"
          href={process.env.REACT_APP_STRAPI_BACKEND + "/admin"}
          target="_blank"
          rel="noreferrer"
        >
          Login
        </a>
      </div>
      <p className="textSm">
        Copyright &copy; by Blueberry Media GbR &middot; All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
