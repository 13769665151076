import React, { useState } from "react";
import { Link } from "react-router-dom";

// import helper
import { scrollToTop } from "../../helper/functions";

// import styles
import "../../css/global.css";
import "./Menu.scoped.css";

const Menu = () => {
  const [active, setActive] = useState(false);

  return (
    <div
      className={"menu shadowFlat" + (active ? " open" : "")}
      onClick={() => setActive(!active)}
    >
      <Link to="/portfolio" className="button" onClick={scrollToTop} />
      <Link to="/about" className="button" onClick={scrollToTop} />
      <Link to="/contact" className="button" onClick={scrollToTop} />
    </div>
  );
};

export default Menu;
